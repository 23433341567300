<template>
	<div id="Confirmation" ref="Confirmation" class="sign-up-modal-confirmation" :class="{ 'd-none': IsHidden }">
		<p class="sign-up-modal-confirmation-tick"><span class="las la-check-circle"></span></p>
		<h1>
			Welcome to YPRL
			<span>{{ FirstName }}</span>
		</h1>
		<p>
			Welcome to YPRL! We are thrilled to have you as a new member of our community. With your registration complete,
			you now have access to a world of knowledge, entertainment and learning resources. From best-selling books to
			educational programs, we have something for everyone. We look forward to serving you and helping you achieve your
			goals.
		</p>
		<p>Please write down your member number.</p>
		<h2>Your member number is:</h2>
		<div style="display: block; margin-top: 10px; margin-bottom: 70px">
			<h3>{{ MemberNumber }}</h3>
			<button @click="redirect" class="btn btn-primary back-secondary" style="background-color:rgba(0, 123, 255, 0.78); border: none; border-radius: 4px;">Continue</button>
		</div>
		<p v-if="!hideButtons">
			<a
				v-show="MyYprlLinkUrl != null"
				:href="MyYprlLinkUrl"
				class="btn btn-primary back-secondary"
				:target="MyYprlLinkTarget"
				style="margin-right: 16px; background-color: #ed5f87;"
			>
				<span class="las la-user-circle"></span>
				MyYPRL Login
			</a>
			<a
				href="/explore/digital-library/yprl-app/"
				class="btn btn-primary back-secondary"
				style="margin-right: 16px; background-color: #00b1c2;"
			>
				YPRL App
				<span class="las la-sign-in-alt"></span>
			</a>
			<a href="/" class="btn btn-primary back-secondary">
				Explore YPRL
				<span class="las la-sign-in-alt"></span>
			</a>
		</p>
	</div>
</template>
<script>
export default {
	name: 'Step06Confirmation',
	data() {
		return {
			IsHidden: true,
			FirstName: null,
			EmailAddress: null,
			MemberNumber: null,
			MyYprlLinkUrl: null,
			MyYprlLinkTarget: null,
			hideButtons: false,
		};
	},
	mounted() {
		var self = this;
		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.get('Source') === 'wifi') {
			self.hideButtons = true;
		}
	},
	methods: {
		init(data) {
			var self = this;
			self.FirstName = data.firstName;
			self.EmailAddress = data.emailAddress;
			self.MemberNumber = data.memberNumber;

			if (data.myYprlLinkUrl !== '') {
				self.MyYprlLinkUrl = data.myYprlLinkUrl;
				self.MyYprlLinkTarget = data.myYprlLinkTarget;
			}
		},
		hide() {
			this.IsHidden = true;
		},
		show() {
			this.IsHidden = false;
		},
		redirect() {
			window.location.href = this.$parent.forwardingURL;
		}
	},
};
</script>