<template>
	<div
		class="tingle-modal tingle-sign-up-container colour-way "
		:class="{ 'tingle-modal--visible': showLibrarySignUpForm }"
	>
		<div class="sign-up-modal-wrapper">
			<div class="tingle-modal-box">
				<div class="tingle-modal-box__content">
					<div id="librarySignUpModalContent" class="sign-up-modal-content-wrapper">
						<div
							class="sign-up-modal-close"
							aria-label="Close locations modal"
							role="button"
							aria-pressed="false"
							tabindex="0"
							@click="closeModal"
							v-if="!disableClose"
						></div>
						<ProgressPanel ref="progressPanel" :NumberOfSteps="NumberOfSteps" />
						<Step01PersonalDetails ref="step01PersonalDetails" @next="step01PersonalDetailsSubmitted" />
						<Step02ParentGuardianDetails
							ref="step02ParentGuardianDetails"
							@back="setStep(1)"
							@next="step02ParentGuardianDetailsSubmitted"
						/>
						<Step03ContactDetails
							ref="step03ContactDetails"
							@back="setStep(NumberOfSteps == 5 ? 1 : 2)"
							@next="step03ContactDetailsSubmitted"
						/>
						<Step04LoginDetails ref="step04LoginDetails" @back="setStep(3)" @next="step04LoginDetailsSubmitted" />
						<Step05AddressDetails ref="step05AddressDetails" @back="setStep(4)" @next="step05AddressDetailsSubmitted" />
						<Step06Confirmation ref="step06Confirmation" />
					</div>
				</div>
				<div class="tingle-modal-box__footer">
					<div class="sign-up-modal-dots">
						<div class="sign-up-modal-dots-container">
							<svg viewBox="0 0 300 1920" xmlns="http://www.w3.org/2000/svg">
								<defs>
									<pattern id="locationModalDots" x="0" y="0" width="61" height="30" patternUnits="userSpaceOnUse">
										<circle cx="6" cy="6" r="6.12293" class="fill-secondary"></circle>
										<circle cx="37" cy="23" r="6.12293" class="fill-secondary"></circle>
									</pattern>
								</defs>
								<rect fill="url(#locationModalDots)" width="300" height="100%"></rect>
							</svg>
						</div>
					</div>
					<div class="sign-up-modal-lines">
						<div class="sign-up-modal-lines-container">
							<svg viewBox="0 0 1920 90" xmlns="http://www.w3.org/2000/svg">
								<defs>
									<pattern id="locationsModalLines" x="0" y="0" width="700" height="90" patternUnits="userSpaceOnUse">
										<rect y="0.453491" width="838" height="10" class="fill-tertiary"></rect>
										<rect y="25.4535" width="838" height="10" class="fill-tertiary"></rect>
										<rect y="50.4535" width="838" height="10" class="fill-tertiary"></rect>
										<rect y="75.4535" width="838" height="10" class="fill-tertiary"></rect>
									</pattern>
								</defs>
								<rect fill="url(#locationsModalLines)" width="100%" height="100%"></rect>
							</svg>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Vue from 'vue';
import ProgressPanel from '@/components/library-sign-up-form/ProgressPanel.vue';
import Step01PersonalDetails from '@/components/library-sign-up-form/Step01PersonalDetails.vue';
import Step02ParentGuardianDetails from '@/components/library-sign-up-form/Step02ParentGuardianDetails.vue';
import Step03ContactDetails from '@/components/library-sign-up-form/Step03ContactDetails.vue';
import Step04LoginDetails from '@/components/library-sign-up-form/Step04LoginDetails.vue';
import Step05AddressDetails from '@/components/library-sign-up-form/Step05AddressDetails.vue';
import Step06Confirmation from '@/components/library-sign-up-form/Step06Confirmation.vue';

export default {
	components: {
		ProgressPanel,
		Step01PersonalDetails,
		Step02ParentGuardianDetails,
		Step03ContactDetails,
		Step04LoginDetails,
		Step05AddressDetails,
		Step06Confirmation,
	},
	props: {
		MyYprlLinkUrl: {
			type: String,
			required: false,
		},
		MyYprlLinkTarget: {
			type: String,
			required: false,
		},
		MyYprlLinkText: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			showLibrarySignUpForm: false,
			NumberOfSteps: 5,
			CurrentStep: 1,
			disableClose: false,
			forwardingURL: null,

			PersonalDetails: null,
			ParentGuardianDetails: null,
			ContactDetails: null,
			LoginDetails: null,
			AddressDetails: null,
		};
	},
	mounted() {
		this.$_loadForm();
	},
	computed: {
		getURLSearchParams: function() {
			return new URLSearchParams(window.location.search);
		},
	},
	methods: {
		$_loadForm() {
			var self = this;
			var urlParams = self.getURLSearchParams;
			self.showLibrarySignUpForm = urlParams.get('ShowLibrarySignUpForm') === 'true';
			self.disableClose = urlParams.get('Source') === 'wifi';

			var localLibrary = urlParams.get('LocalLibrary');
			if (localLibrary) {
				self.$refs.step05AddressDetails.setLocalLibrary(localLibrary);
			}

			var base64Url = urlParams.get('ForwardingURL');
			if (base64Url) {
				try {
					self.forwardingURL = atob(base64Url); // Decode Base64
				} catch (e) {
					console.error('Error decoding ForwardingURL:', e);
				}
			}else{
				self.forwardingURL = "/";
			}

			var testWithExisitingBorrower = false;

			var debug = {
				enabled: urlParams.get('Debug') === 'true',
				firstName: testWithExisitingBorrower ? 'Test One' : 'Jake',
				lastName: testWithExisitingBorrower ? 'Koben' : 'Williamson',
				dateOfBirth: testWithExisitingBorrower ? new Date(2023, 0, 1, 0, 0, 0, 0) : new Date(1975, 5, 26, 0, 0, 0, 0),
				emailAddress: 'jake@koben.com.au',
				phoneNumber: '0404040404',
				languagesSpoken: new Array('ENG'),
				password: '1234a',
				confirmPassword: '1234a',
			};

			self.$refs.step01PersonalDetails.init(debug);
			self.$refs.step03ContactDetails.init(debug);
			self.$refs.step04LoginDetails.init(debug);

			self.setStep(1);
		},
		step01PersonalDetailsSubmitted: function(data) {
			var self = this;

			console.log('step01PersonalDetailsSubmitted');
			console.log(data);

			self.PersonalDetails = data;

			self.NumberOfSteps = self.PersonalDetails.ParentGuardianRequired ? 6 : 5;

			if (self.NumberOfSteps === 5) {
				self.ParentGuardianDetails = null;
			}

			self.setStep(self.NumberOfSteps == 5 ? 3 : 2);
		},
		step02ParentGuardianDetailsSubmitted: function(data) {
			var self = this;

			console.log('step02ParentGuardianDetailsSubmitted');
			console.log(data);

			self.ParentGuardianDetails = data;

			self.setStep(3);
		},
		step03ContactDetailsSubmitted: function(data) {
			var self = this;

			console.log('step03ContactDetailsSubmitted');
			console.log(data);

			self.ContactDetails = data;

			self.setStep(4);
		},
		step04LoginDetailsSubmitted: function(data) {
			var self = this;

			console.log('step04LoginDetailsSubmitted');
			console.log(data);

			self.LoginDetails = data;

			self.setStep(5);
		},
		step05AddressDetailsSubmitted: function(data) {
			var self = this;

			console.log('step05AddressDetailsSubmitted');
			console.log(data);

			self.AddressDetails = data;

			self.ProcessFormSubmission();
		},
		ProcessFormSubmission: function() {
			var self = this;

			var request = {
				FirstName: self.PersonalDetails.FirstName,
				LastName: self.PersonalDetails.LastName,
				DateOfBirth: self.PersonalDetails.DateOfBirth,
				Gender: self.PersonalDetails.Gender,
				SelfDescribedGender: self.PersonalDetails.SelfDescribedGender,
				ParentGuardianRequired: self.PersonalDetails.ParentGuardianRequired,

				EmailAddress: self.ContactDetails.EmailAddress,
				PhoneNumber: self.ContactDetails.PhoneNumber,
				LanguagesSpokenAtHome: self.ContactDetails.LanguagesSpokenAtHome,
				SubscribeToNewsletters: self.ContactDetails.SubscribeToNewsletters,

				Password: self.LoginDetails.Password,
				ConfirmPassword: self.LoginDetails.ConfirmPassword,

				AddressStreet: self.AddressDetails.Street,
				AddressSuburb: self.AddressDetails.Suburb,
				AddressState: self.AddressDetails.State,
				AddressPostcode: self.AddressDetails.Postcode,
				ResidentialArea: self.AddressDetails.ResidentialArea,
				LocalLibrary: self.AddressDetails.LocalLibrary,
				TermsAndConditionsAccepted: self.AddressDetails.TermsAndConditionsAccepted,
			};

			if (self.ParentGuardianDetails !== null) {
				request.ParentGuardianFirstName = self.ParentGuardianDetails.ParentGuardianFirstName;
				request.ParentGuardianLastName = self.ParentGuardianDetails.ParentGuardianLastName;
				request.ParentGuardianPhoneNumber = self.ParentGuardianDetails.ParentGuardianPhoneNumber;
				request.ParentGuardianEmailAddress = self.ParentGuardianDetails.ParentGuardianEmailAddress;
			}

			console.log('ProcessFormSubmission');
			console.log(request);

			Vue.ProcessFormSubmission(
				request,
				function(response) {
					console.log('this is ProcessFormSubmission response:');
					console.log(response);
					if (response.data) {
						self.$refs.step06Confirmation.init({
							firstName: self.PersonalDetails.FirstName,
							emailAddress: self.PersonalDetails.EmailAddress,
							memberNumber: response.data.MemberNumber,
							myYprlLinkUrl: self.MyYprlLinkUrl,
							myYprlLinkTarget: self.MyYprlLinkTarget,
							myYprlLinkText: self.MyYprlLinkText,
						});
						self.setStep(6);
					} else {
						self.$refs.step05AddressDetails.resetSubmittedAndDisplayError();
					}
				},
				function(response) {
					console.log('errorCallback');
					console.log(response);
					self.$refs.step05AddressDetails.resetSubmittedAndDisplayError();
				}
			);
		},
		setStep(step) {
			var self = this;
			switch (step) {
				case 1:
					self.CurrentStep = 1;
					self.$refs.progressPanel.show();
					self.$refs.progressPanel.setStep(1);
					self.$refs.step01PersonalDetails.show();
					self.$refs.step02ParentGuardianDetails.hide();
					self.$refs.step03ContactDetails.hide();
					self.$refs.step04LoginDetails.hide();
					self.$refs.step05AddressDetails.hide();
					self.$refs.step06Confirmation.hide();
					break;
				case 2:
					self.CurrentStep = 2;
					self.$refs.progressPanel.show();
					self.$refs.progressPanel.setStep(2);
					self.$refs.step01PersonalDetails.hide();
					self.$refs.step02ParentGuardianDetails.show();
					self.$refs.step03ContactDetails.hide();
					self.$refs.step04LoginDetails.hide();
					self.$refs.step05AddressDetails.hide();
					self.$refs.step06Confirmation.hide();
					break;
				case 3:
					self.CurrentStep = 3;
					self.$refs.progressPanel.show();
					self.$refs.progressPanel.setStep(3);
					self.$refs.step01PersonalDetails.hide();
					self.$refs.step02ParentGuardianDetails.hide();
					self.$refs.step03ContactDetails.show();
					self.$refs.step04LoginDetails.hide();
					self.$refs.step05AddressDetails.hide();
					self.$refs.step06Confirmation.hide();
					break;
				case 4:
					self.CurrentStep = 4;
					self.$refs.progressPanel.setStep(4);
					self.$refs.step01PersonalDetails.hide();
					self.$refs.step02ParentGuardianDetails.hide();
					self.$refs.step03ContactDetails.hide();
					self.$refs.step04LoginDetails.show();
					self.$refs.step05AddressDetails.hide();
					self.$refs.step06Confirmation.hide();
					break;
				case 5:
					self.CurrentStep = 5;
					self.$refs.progressPanel.setStep(5);
					self.$refs.step01PersonalDetails.hide();
					self.$refs.step02ParentGuardianDetails.hide();
					self.$refs.step03ContactDetails.hide();
					self.$refs.step04LoginDetails.hide();
					self.$refs.step05AddressDetails.show();
					self.$refs.step06Confirmation.hide();
					break;
				case 6:
					self.CurrentStep = 6;
					self.$refs.progressPanel.hide();
					self.$refs.step01PersonalDetails.hide();
					self.$refs.step02ParentGuardianDetails.hide();
					self.$refs.step03ContactDetails.hide();
					self.$refs.step04LoginDetails.hide();
					self.$refs.step05AddressDetails.hide();
					self.$refs.step06Confirmation.show();
					break;
			}
		},
		closeModal() {
			var self = this;
			if (!self.disableClose) {
				self.showLibrarySignUpForm = false;
			}
		},
	},
};
</script>
